import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GamesQueryService {
  constructor() {}

  getGameBySlugQuery(value: string, take: any, skip: string, country?: string) {
    return {
      query: `{gamesByCategory(take: ${take}, skip: ${skip}, slug: "${value}", country: "${country}") {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug } image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId, text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getAllGamesQuery(value: string, country?: string, take?: string, lang?: string) {
    const takeGames = take ? take : '24';
    return {
      query: `{
        game(btake: ${takeGames}, skip: ${value}, orderby: { sort: { sort: desc } }, where: {  countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } },isGameActive: { equals: true }}) {idhasDemo merchant {  name alias }gameCategories {  slug }imageimageFullPathisVirtualtext { name_${lang} name_en } pageCodes {  launch mobileUrl url default external  mobileExternal  mobileAndroid  mobileWindows } merchantIdparentMerchantIdLikes { gameId  likes }}}`,
    };
  }
  searchGameByNameQuery(take: number, skip: string, value: string, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: { sort: { sort: desc } }, where: { AND: [ { text: { is: { name_en: { contains: "${value}", mode: insensitive } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } } ] } ) { id hasDemo gameCategories { slug } image imageFullPath isVirtual merchantId text { name_en } merchant { name alias } pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileWindows } merchantId parentMerchantId Likes { gameId likes } } }`,
    };
  }
  getGamesByMerchantId(take: number, skip: string, merchantId: any, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [ { merchantSort: { sort: desc } }, { sort: { sort: desc } } ], where: { AND: [ { merchant: { is: { id: { in: [${merchantId}] } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } } ] } ) { id isVirtual hasDemo gameCategories { slug } image imageFullPath merchantId merchant { name alias } pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileWindows } parentMerchantId text { name_en } Likes { gameId likes } } }`,
    };
  }
  getSimilarCategory(value: string) {
    return {
      query: `{ category( orderby: { cSort: desc }, where: { AND: [ { tags: { some: { tag: { is: { name: { contains: "similar" } } } } } }, { games: { some: { gameId: { equals: "${value}" } } } }, { isGameActive: { equals: true } }   ] } ) { id } }`,
    };
  }
  getGameByCategories(take: number, value: any, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: 0, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { AND: [ { categories: { some: { categoryId: { in: [${value}] } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } Likes { gameId, likes } } }`,
    };
  }
  getGamesBySlugAndFilter(take: number, skip: string, categories?: any, merchants?: any, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { AND: [ { merchantId: { in: [${merchants}] } }, { categories: { some: { category: { is: { slug: { equals: "${categories}" } } } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } Likes { gameId, likes } } }`,
    };
  }
  getGamesBySlugAndName(take: number, skip: string, categories: any, search: string, country?: string) {
    return {
      query: `{game (take: ${take}, skip: ${skip}, orderby: [{merchantSort: {sort:desc}}, {sort: {sort:desc}}], where: {AND: [{ text: { is: { name_en: { contains: "${search}", mode: insensitive }}}},{ categories: { some: { category: { is: {slug: {equals: "${categories}"}}}}}},{countryRestriction: {is: {countries: {none: {country: {equals: "${country}", mode: insensitive}}}}}} ]}) {id hasDemo isVirtual merchant{ name, alias } gameCategories{ slug } image imageFullPath merchantId sort pageCodes { launch, mobileUrl, url, default, external, mobileExternal, mobileAndroid, mobileExternal, mobileWindows, }, parentMerchantId  text {name_en} Likes { gameId, likes } } }`,
    };
  }
  getGamesByFilterAndName(take: number, skip: string, merchants: any, search: string, country?: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { AND: [ { text: { is: { name_en: { contains: "${search}", mode: insensitive } } } }, { merchantId: { in: [${merchants}] } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } Likes { gameId, likes } } }`,
    };
  }
  getFilteredGames(take: number, skip: string, categories: any, merchants: any, country: string, search: string) {
    return {
      query: `{ game( take: ${take}, skip: ${skip}, orderby: [{ merchantSort: { sort: desc } }, { sort: { sort: desc } }], where: { AND: [ { text: { is: { name_en: { contains: "${search}", mode: insensitive } } } }, { merchantId: { in: [${merchants}] } }, { categories: { some: { category: { is: { slug: { equals: "${categories}" } } } } } }, { countryRestriction: { is: { countries: { none: { country: { equals: "${country}", mode: insensitive } } } } } }, { isGameActive: { equals: true } }   ] } ) { id hasDemo isVirtual merchant { name, alias } Likes { gameId, likes } gameCategories { slug } image imageFullPath merchantId sort pageCodes { launch mobileUrl url default external mobileExternal mobileAndroid mobileExternal mobileWindows } parentMerchantId text { name_en } Likes { gameId, likes } } }`,
    };
  }
  getAllGames(take: number, skip: string) {
    //, lang?: string, country?: string
    return {
      query: `query { game(take: ${take}, skip: ${skip}, orderby: { merchant: { sort: desc } } ) { RTP active activeIntegration backgroundImage hasFreeSpins id image isBonusBuy isMegaways maxBet merchantId minBet restrictionListId softgamingId subMerchantId  translations { language name
        } } }`,
    };
  }
}
/*
// This is your Prisma schema file,
// learn more about it in the docs: https://pris.ly/d/prisma-schema

generator nestgraphql {
  provider = "node node_modules/prisma-nestjs-graphql"
  output   = "../src/@generated/prisma-nestjs-graphql"
  // previewFeatures = ["orderByRelation", "referentialActions"]
}

datasource db {
  provider = "postgresql"
  url      = env("DATABASE_URL")
}

generator client {
  provider = "prisma-client-js"
}

model User {
  id    Int     @id @default(autoincrement())
  email String  @unique
  name  String?
}

model CategoryTranslation {
  id         Int      @id @default(autoincrement())
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int      @unique
  name_bg    String   @default("")
  name_da    String   @default("")
  name_de    String   @default("")
  name_ee    String   @default("")
  name_el    String   @default("")
  name_en    String   @default("")
  name_es    String   @default("")
  name_fi    String   @default("")
  name_fr    String   @default("")
  name_id    String   @default("")
  name_it    String   @default("")
  name_gr    String   @default("")
  name_ja    String   @default("")
  name_ko    String   @default("")
  name_lt    String   @default("")
  name_nl    String   @default("")
  name_no    String   @default("")
  name_pl    String   @default("")
  name_pt    String   @default("")
  name_ro    String   @default("")
  name_ru    String   @default("")
  name_sk    String   @default("")
  name_sv    String   @default("")
  name_th    String   @default("")
  name_tr    String   @default("")
  name_ua    String   @default("")
  name_uk    String   @default("")
  name_nb    String   @default("")
  name_vi    String   @default("")
  name_zh    String   @default("")
  name_hi    String   @default("")
}

model Category {
  id            Int                  @id
  text          CategoryTranslation?
  cSort         Int
  cSubSort      Int
  slug          String
  customSort    String[]
  menuId        String
  games         CategoriesOnGames[]
  categoryGames Game[]
  tags          TagsOnCategories[]
}

model Tag {
  id         Int                @id @default(autoincrement())
  name       String             @unique
  categories TagsOnCategories[]
}

model TagsOnCategories {
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int // relation scalar field (used in the @relation attribute above)
  tag        Tag?     @relation(fields: [tagId], references: [id], onDelete: Cascade)
  tagId      Int // relation scalar field (used in the @relation attribute above)

  @@id([categoryId, tagId])
}

model CategoriesOnGames {
  game       Game     @relation(fields: [gameId], references: [id], onDelete: Cascade)
  gameId     String // relation scalar field (used in the @relation attribute above)
  category   Category @relation(fields: [categoryId], references: [id], onDelete: Cascade)
  categoryId Int // relation scalar field (used in the @relation attribute above)
  sort       Int?

  @@id([gameId, categoryId])
}

Mi, [11/09/2024 15:33]
model GameTranslation {
  id             Int     @id @default(autoincrement())
  game           Game    @relation(fields: [gameId], references: [id], onDelete: Cascade)
  gameId         String  @unique
  name_bg        String  @default("")
  name_da        String  @default("")
  name_de        String  @default("")
  name_ee        String  @default("")
  name_el        String  @default("")
  name_en        String  @default("")
  name_es        String  @default("")
  name_fi        String  @default("")
  name_fr        String  @default("")
  name_id        String  @default("")
  name_it        String  @default("")
  name_gr        String  @default("")
  name_ja        String  @default("")
  name_ko        String  @default("")
  name_lt        String  @default("")
  name_nb        String  @default("")
  name_nl        String  @default("")
  name_no        String  @default("")
  name_pl        String  @default("")
  name_pt        String  @default("")
  name_ro        String  @default("")
  name_ru        String  @default("")
  name_sk        String  @default("")
  name_sv        String  @default("")
  name_th        String  @default("")
  name_tr        String  @default("")
  name_ua        String  @default("")
  name_uk        String  @default("")
  name_vi        String  @default("")
  name_zh        String  @default("")
  name_hi        String  @default("")
  description_bg String?
  description_da String?
  description_de String?
  description_ee String?
  description_el String?
  description_en String?
  description_es String?
  description_fi String?
  description_fr String?
  description_id String?
  description_it String?
  description_gr String?
  description_ja String?
  description_ko String?
  description_lt String?
  description_nb String?
  description_nl String?
  description_no String?
  description_pl String?
  description_pt String?
  description_ro String?
  description_ru String?
  description_sk String?
  description_sv String?
  description_th String?
  description_tr String?
  description_ua String?
  description_uk String?
  description_vi String?
  description_zh String?
  description_hi String?
}

model PageCodes {
  id             Int     @id @default(autoincrement())
  url            String
  mobileUrl      String?
  default        String
  mobile         String?
  mobileAndroid  String?
  mobileWindows  String?
  external       String?
  mobileExternal String?
  launch         String
  game           Game?
}

Mi, [11/09/2024 15:33]
model Game {
  id                   String              @id
  categories           CategoriesOnGames[]
  gameCategories       Category[]
  text                 GameTranslation?
  image                String?
  imageFullPath        String?
  imageMobile          String?
  background           String?
  branded              Boolean
  superBranded         Boolean
  hasDemo              Boolean
  merchant             Merchant            @relation(name: "merchant", fields: [merchantId], references: [id], onDelete: Cascade)
  merchantId           Int // relation scalar field (used in the @relation attribute above)
  parentMerchantId     Int? // relation scalar field (used in the `@relation)
  subMerchant          Merchant?           @relation(name: "subMerchant", fields: [subMerchantId], references: [id], onDelete: Cascade)
  subMerchantId        Int? // relation scalar field (used in the `@relation` attribute above)
  aR                   String?
  countryRestriction   CountryRestriction? @relation(fields: [countryRestrictionId], references: [id], onDelete: Cascade)
  countryRestrictionId Int? // relation scalar field (used in the @relation attribute above)
  sort                 Int?
  merchantSort         Int?
  pageCodes            PageCodes           @relation(fields: [pageCodesId], references: [id], onDelete: Cascade)
  pageCodesId          Int                 @unique // relation scalar field (used in the @relation attribute above)
  workingHours         String?
  isVirtual            Boolean
  tableID              String
  RTP                  String?
  freeround            Boolean?
  isRestricted         Boolean
  Likes                Likes?
  minBetDefault        Float?
  maxBetDefault        Float?
  maxMultiplier        Float?
  bonusBuy             Boolean?
  megaways             Boolean?
  freespins            Boolean?
  freeBonus            Boolean?
}

model Likes {
  id     Int     @id @default(autoincrement())
  gameId String? @unique
  likes  Int     @default(0)
  game   Game?   @relation(fields: [gameId], references: [id], onDelete: Cascade)

  @@index([gameId])
}

model Merchant {
  id         Int                     @id
  name       String
  alias      String
  image      String
  weight     Int
  menuId     String
  parent     Merchant?               @relation(name: "parent", fields: [parentId], references: [id], onDelete: Cascade)
  parentId   Int?
  gamesCount Int?
  childs     Merchant[]              @relation(name: "parent")
  games      Game[]                  @relation(name: "merchant")
  subGames   Game[]                  @relation(name: "subMerchant")
  currencies CurrenciesOnMerchants[]
}

model CountryRestriction {
  id            Int                         @id
  name          String
  isDefault     Boolean
  apiTemplateId Int?
  countries     CountryRestrictionCountry[]
  games         Game[]
}

model CountryRestrictionCountry {
  id                   Int                @id @default(autoincrement())
  country              String
  countryRestriction   CountryRestriction @relation(fields: [countryRestrictionId], references: [id], onDelete: Cascade)
  countryRestrictionId Int // relation scalar field (used in the @relation attribute above)
}

model Currency {
  id        Int                     @id @default(autoincrement())
  name      String                  @unique
  merchants CurrenciesOnMerchants[]
}

model CurrenciesOnMerchants {
  merchant   Merchant @relation(fields: [merchantId], references: [id], onDelete: Cascade)
  merchantId Int // relation scalar field (used in the @relation attribute above)
  currency   Currency @relation(fields: [currencyId], references: [id], onDelete: Cascade)
  currencyId Int // relation scalar field (used in the @relation attribute above)

  @@id([merchantId, currencyId])
}
  */
